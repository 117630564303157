import React, { useEffect, useState } from "react";
import "./Lumi.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";

function Lumi() {
  const projectName = "LUMI";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null);

  useEffect(() => {
    // Check if project data is cached in sessionStorage
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);
  return (
    <div className="lumi-container">
      <div className="lumi-desktop">
        {cachedProject != null ? (
          <React.Fragment>
            <div className="lumiFirstImage">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }
                alt={cachedProject.images[0].alt}
              />
              <div className="logo-lumi">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[1].imageUrl
                  }
                  alt={cachedProject.images[1].alt}
                />
              </div>

              <div className="lumiproject-description">
                {cachedProject.description}
              </div>
            </div>
            <div
              className="lumi-second-row"
              style={{
                backgroundImage: `url('${
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[2].imageUrl
                }')`,
              }}
            >
              <div className="d-flex gap-5">
                <div className="lumi-bottle-image">
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES +
                      cachedProject.images[3].imageUrl
                    }
                    alt={cachedProject.images[3].alt}
                  />
                  <p className="lumi-bottle-image-desc">AI GENERATED ASSETS</p>
                </div>
                <div>
                  <p className="lumi-secondrow-desc">
                    Our brand is all about enhancing your natural beauty with a
                    glossy, dewy ﬁnish that’s both edgy and effortlessly chic.
                    <br />
                    <br /> Elevate your skincare routine and let your inner
                    radiance shine through with LuminEdge.
                  </p>
                  <h1 className="lumi-secondrow-name">
                    {cachedProject.images[2].name}
                  </h1>
                </div>
              </div>
            </div>
            {cachedProject.images.length > 4 && (
              <div className="dynamic-rows">
                {cachedProject.images.slice(4).map((image, index) => (
                  <div className="dynamic-row" key={index}>
                    <img
                      loading="lazy"
                      src={
                        process.env.REACT_APP_BACKEND_URL_IMAGES +
                        image.imageUrl
                      }
                      alt={image.alt}
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
      <div className="lumi-mobile">
        {cachedProject != null ? (
          <React.Fragment>
            <div className="lumiFirstImage-mobile">
              <img
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }
                alt={cachedProject.images[0].alt}
              />
              <div className="logo-lumi-mobile">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[1].imageUrl
                  }
                  alt={cachedProject.images[1].alt}
                />
              </div>
            </div>
            <div className="lumi-bottle-image-mobile">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[3].imageUrl
                }
                alt={cachedProject.images[3].alt}
              />
            </div>
            <div className="lumi-second-row-mobile">
              <div className="lum-mobile-image">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[2].imageUrl
                  }
                  alt={cachedProject.images[2].alt}
                />
              </div>
              <div className="lumi-mobile-desc">
                <p className="lumi-secondrow-desc-mobile">
                  Our brand is all about enhancing your natural beauty with a
                  glossy, dewy ﬁnish that’s both edgy and effortlessly chic.
                  <br />
                  <br /> Elevate your skincare routine and let your inner
                  radiance shine through with LuminEdge.
                </p>
                <h1 className="lumi-secondrow-name-mobile">
                  {cachedProject.images[2].name}
                </h1>
              </div>
            </div>
            {cachedProject.images.length > 4 && (
              <div className="dynamic-rows">
                {cachedProject.images.slice(4).map((image, index) => (
                  <div className="dynamic-row" key={index}>
                    <img
                      loading="lazy"
                      src={
                        process.env.REACT_APP_BACKEND_URL_IMAGES +
                        image.imageUrl
                      }
                      alt={image.alt}
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default Lumi;
