import React, { useEffect, useState } from "react";
import "./home.css";
import WorkAlongSide from "../../components/HomePage/WorkAlongSide/WorkAlongSide";
import axios from "axios";
import BrandIcons from "../../components/HomePage/BrandIcons/BrandIcons";
import LetsConnect from "../../components/HomePage/LestConnect/LetsConnect";
import HomeSlider from "../../components/HomePage/Slider/Slider";
import FeaturedWork from "../../components/HomePage/FeaturedWork/FeaturedWork";

function Home() {
  // const [brands, setBrands] = useState(null);
  const [ProjectVideos, setProjectVideos] = useState(null);
  const [brandNames, setBrandNames] = useState(null);
  const [brandIcons, setBrandIcons] = useState(null);
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    getBrands();
    getHomePageVideos();
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/Projects"
      );

      if (response.data) {
        const sortedProjects = response.data.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        setProjects(sortedProjects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHomePageVideos = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          "api/ProjectVideos/GetHomePageVideos"
      );

      if (response.data) {
        setProjectVideos(response.data);
      }
    } catch (error) {
      console.error("Error fetching project Videos:", error);
    }
  };

  //getting the work along side brand
  const getBrands = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/projectIcons"
      );

      if (response.data) {
        // setBrands(response.data);
        const brandNames = response.data.map((brand) => brand.name);
        setBrandNames(brandNames);
        const brandIcons = response.data.map((brand) => ({
          name: brand.name,
          icon: brand.iconUrl,
        }));
        setBrandIcons(brandIcons);
      }
    } catch (error) {
      console.error("Error fetching projectIcons:", error);
    }
  };
  return (
    <div className="home-container">
      {projects && <HomeSlider projects={projects} />}
      {ProjectVideos && <FeaturedWork videos={ProjectVideos} />}
      {brandNames && <WorkAlongSide brands={brandNames} />}
      {brandIcons && <BrandIcons icons={brandIcons} />}
      <LetsConnect />
    </div>
  );
}

export default Home;
