import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedin, setisLoggedin] = useState(false);

  const loginUser = (userData) => {
    setUser(userData);
    setisLoggedin(true);
  };

  const logout = () => {
    setUser(null);
    setisLoggedin(false);
  };
  const updateUser = (updatedUser) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...updatedUser,
    }));
  };

  return (
    <AuthContext.Provider
      value={{ user, loginUser, logout, isLoggedin, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
