import React, { useState } from "react";
import "./signin.css";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";

function Signin() {
  const { loginUser } = useAuth();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    email: "",
    password: "",
  });

  const [apiMessage, setapiMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "api/User/SignIn",
      data
    );
    setLoading(false);

    if (response.data.success) {
      loginUser(response.data.user);
      navigate("/dashboard/projects");
      setapiMessage("");
    } else {
      setapiMessage(response.data.message);
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="signin-container">
      <div className="backgroundoverlay">
        <div className="login-form">
          <div className="user-icon">
            <img
              loading="lazy"
              style={{ width: "60%", height: "30%" }}
              src={logo}
              alt=""
            />
          </div>
          {apiMessage !== "" ? (
            <p className="apiErrorMessage">{apiMessage}</p>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column">
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  ></label>
                  <div className="p-inputgroup">
                    <InputText
                      id={field.name}
                      value={field.value || ""}
                      className={classNames({ "p-invalid": fieldState.error })}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: "Password is required." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  ></label>
                  <div className="p-inputgroup">
                    <Password
                      id={field.name}
                      value={field.value || ""}
                      className={classNames({ "p-invalid": fieldState.error })}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Password"
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />

            {/* Submit Button */}
            <div className="p-inputgroup">
              <Button type="submit" label="Sign In" loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signin;
