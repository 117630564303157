import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Signin from "../Dashboard/Auth/signin/signin";
import Projects from "../Dashboard/pages/projects/Projects";
import { useAuth } from "../Dashboard/Auth/AuthContext";
import Categories from "../Dashboard/pages/category/Categories";
import ProjectImages from "../Dashboard/pages/ProjectImages/ProjectImages";
import ProjectVideos from "../Dashboard/pages/ProjectVideos/ProjectVideos";
import Settings from "../Dashboard/pages/settings/settings";
import ProjectIcons from "../Dashboard/pages/ProjectIcons/ProjectIcons";
import PageNotFound from "../PageNotFound/PageNotFound";

function DashboardRoutes() {
  const { isLoggedin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedin) {
      navigate("/dashboard/signin");
    }
  }, [isLoggedin, navigate]);

  return (
    <Routes>
      {isLoggedin ? (
        <>
          {/* <Route index element={<Index />} /> */}
          <Route path="projects" index element={<Projects />} />
          <Route path="categories" element={<Categories />} />
          <Route path="projectIcons" element={<ProjectIcons />} />
          <Route path="projectImages" element={<ProjectImages />} />
          <Route path="projectVideos" element={<ProjectVideos />} />
          <Route path="settings" element={<Settings />} />
        </>
      ) : (
        // If the user is not logged in, redirect to the sign-in page
        <Route path="*" element={<Navigate to="signin" />} />
      )}
      <Route path="signin" element={<Signin />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default DashboardRoutes;
