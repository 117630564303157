import React, { useState, useEffect } from "react";
import "./SmellyCat.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";

function SmellyCat() {
  const projectName = "SMELLY CAT AGENCY";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null); // State to store cached project data

  useEffect(() => {
    // Check if project data is cached in sessionStorage
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);

  return (
    <div className="smellycat-container">
      {cachedProject != null ? (
        <React.Fragment>
          <div className="smellycat-first-row">
            <div className="smelly-desc-mobile">
              {cachedProject.description}
            </div>
            <div className="cat-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }
                alt={cachedProject.images[0].alt}
              />
            </div>
            <div className="smelly-description">
              {cachedProject.description}
            </div>
          </div>
          <div
            className="smellycat-second-row"
            style={{
              backgroundImage: `url('${
                process.env.REACT_APP_BACKEND_URL_IMAGES +
                cachedProject.images[1].imageUrl
              }')`,
            }}
          >
            <div className="smellycat-logo-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[3].imageUrl
                }
                alt={cachedProject.images[3].alt}
              />
            </div>
            <div className="smellycat-multiple-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[2].imageUrl
                }
                alt={cachedProject.images[2].alt}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default SmellyCat;
