import React, { useState, useEffect } from "react";
import "./AiExperimenting.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Slider from "react-slick";
import Loading from "../../../components/Loading/Loading";

function AiExperimenting() {
  const projectName = "AI EXPERIMENTING";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null);

  useEffect(() => {
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div className="aiExperimenting-container">
      {cachedProject != null ? (
        <React.Fragment>
          <div className="ai-desktop">
            <div className="ai-slider">
              <Slider {...settings}>
                {cachedProject.images.map((image, index) => (
                  <div className="ai-image-container" key={index}>
                    <img
                      loading="lazy"
                      src={
                        process.env.REACT_APP_BACKEND_URL_IMAGES +
                        image.imageUrl
                      }
                      alt={image.alt}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="ai-title">{cachedProject.description}</div>
          </div>
          <div className="ai-mobile">
            <div className="ai-title">{cachedProject.description}</div>
            <div className="mobile-ai-image-container">
              {cachedProject.images.map((image, index) => (
                <div className="mobile-image" key={index}>
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default AiExperimenting;
