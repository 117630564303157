import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import "./Slider.css";

function HomeSlider({ projects }) {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
    ],
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };
  const heights = [];
  if (projects) {
    const defaultHeights = ["60vh", "40vh", "50vh", "50vh"];
    for (let i = 0; i < projects.length; i++) {
      const defaultIndex = i % defaultHeights.length;
      heights.push(defaultHeights[defaultIndex]);
    }
  }

  const Navigateto = (linkName) => {
    if (!isDragging) {
      navigate("/project/" + linkName);
    }
  };

  return (
    <React.Fragment>
      <div id="HomeSlider" className="slider-container" ref={sliderRef}>
        <Slider {...settings}>
          {projects != null &&
            projects.map((card, index) => (
              <Card
                card={card}
                key={card.id}
                height={heights[index]}
                navigateTo={Navigateto}
              />
            ))}
        </Slider>
      </div>
      <div className="slider-home-mobile">
        {projects.map((project, index) => (
          <Link
            className="slider-image-mobile-container"
            key={index}
            to={"/project/" + project.name.replace(/\s+/g, "-").toLowerCase()}
          >
            <img
              loading="lazy"
              src={process.env.REACT_APP_BACKEND_URL_IMAGES + project.imageUrl}
              alt={project.name}
            />
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
}

const Card = ({ card, height, navigateTo }) => {
  const { imageUrl, name, category } = card;
  const linkName = name.replace(/\s+/g, "-").toLowerCase();
  const cardStyle = {
    height: height,
  };

  const handleNavigate = () => {
    navigateTo(linkName);
  };

  return (
    <div className="card" onClick={handleNavigate} style={cardStyle}>
      <img
        loading="lazy"
        src={process.env.REACT_APP_BACKEND_URL_IMAGES + imageUrl}
        alt={name}
      />
      <div className="overlay">
        <h4>{name}</h4>
        <p>{category.name}</p>
      </div>
    </div>
  );
};

export default HomeSlider;
