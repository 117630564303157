import { useEffect, useState } from "react";
import axios from "axios";

const useFetchProjectByName = (projectName) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}api/Projects/ByName/${projectName}`
        );

        if (response.data) {
          setProject(response.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (projectName) {
      fetchProject();
    }
  }, [projectName]);

  return { project, loading, error };
};

export default useFetchProjectByName;
