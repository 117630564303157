import React, { useState, useEffect } from "react";
import "./marianne.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";

function MarianneJabr() {
  const projectName = "MARIANNE JABR ARCHITECTURE";
  const { project } = useFetchProjectByName(projectName);
  // Local state to store the cached project
  const [cachedProject, setCachedProject] = useState(null);

  useEffect(() => {
    // Check if project data is cached in sessionStorage
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      console.log(1);
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);
  return (
    <div className="marianne-container">
      {cachedProject != null ? (
        <React.Fragment>
          <div className="marianne-desktop">
            <div
              className="marianne-first-row"
              style={{
                backgroundImage: `url('${
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }')`,
              }}
            >
              <div className="logo-image">
                <div className="image-content">
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES +
                      cachedProject.images[1].imageUrl
                    }
                    alt={cachedProject.images[1].alt}
                  />
                </div>
              </div>
              <div className="marianne-desc">{cachedProject.description}</div>
            </div>

            <div className="mariane-second-row">
              <div className="left-image">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[2].imageUrl
                  }
                  alt={cachedProject.images[2].alt}
                />
              </div>
              <div className="right-text">
                {cachedProject.images[2].alt
                  .split(".")
                  .filter((sentence) => sentence.trim() !== "")
                  .map((sentence, index) => (
                    <p key={index} className="description-text">
                      {sentence.trim()}.
                      <br />
                    </p>
                  ))}
              </div>
            </div>
            <div className="dynamic-rows">
              {cachedProject.images.slice(3).map((image, index) => (
                <div className="dynamic-row" key={index}>
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="marianne-mobile">
            <div
              className="marianne-first-row"
              style={{
                backgroundImage: `url('${
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }')`,
              }}
            >
              <div className="marianne-desc">{cachedProject.description}</div>
              <div className="image-content-mobile">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[1].imageUrl
                  }
                  alt={cachedProject.images[1].alt}
                />
              </div>
            </div>
            <div className="mariane-mobile-secondrow">
              <div className="marianne-secondrow-image">
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                    cachedProject.images[2].imageUrl
                  }
                  alt={cachedProject.images[2].alt}
                />
              </div>
              <div className="mariane-mobile-text">
                {cachedProject.images[2].alt
                  .split(".")
                  .filter((sentence) => sentence.trim() !== "")
                  .map((sentence, index) => (
                    <p key={index} className="description-text">
                      {sentence.trim()}.
                      <br />
                    </p>
                  ))}
              </div>
            </div>
            <div className="dynamic-rows">
              {cachedProject.images.slice(3).map((image, index) => (
                <div className="dynamic-row" key={index}>
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default MarianneJabr;
