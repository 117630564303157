import React, { useState, useEffect } from "react";
import "./Mac.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";
import Slider from "react-slick";
import axios from "axios";

function Mac() {
  const projectName = "MAC";
  const { project } = useFetchProjectByName(projectName); // Hook called directly
  const [cachedProject, setCachedProject] = useState(null);
  //const { project } = useFetchProjectByName(projectName);
  const [icons, setIcons] = useState(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false); // State to track user interaction
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");

  const iconsNames = [
    "ESTEE LAUDER",
    "BOBBI BROWN",
    "OUNASS",
    "TOO FACED",
    "SEPHORA",
    "M.A.C COSMETICS",
    "NAMSHI",
  ];

  useEffect(() => {
    // Check if project data is cached in sessionStorage
    const cachedProject = sessionStorage.getItem(projectName);

    if (cachedProject) {
      setCachedProject(JSON.parse(cachedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }

    // Check if icons data is cached in sessionStorage
    const cachedIcons = sessionStorage.getItem("Macicons");
    if (cachedIcons) {
      setIcons(JSON.parse(cachedIcons));
    } else {
      getBrands();
    }

    setIsSmallScreen(mediaQuery.matches);
  }, [project, projectName]);

  const getBrands = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "api/projectIcons"
      );

      if (response.data) {
        const filteredIcons = response.data.filter((icon) =>
          iconsNames.includes(icon.name.toUpperCase())
        );
        setIcons(filteredIcons);
        // Cache the icons data in sessionStorage
        sessionStorage.setItem("Macicons", JSON.stringify(filteredIcons));
      }
    } catch (error) {
      console.error("Error fetching projectIcons:", error);
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };
  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };

  return (
    <div className="mac-container" onClick={handleDocumentInteraction}>
      <div className="mac-desktop-version">
        {cachedProject != null && icons != null ? (
          <React.Fragment>
            <div className="mac-icons">
              {iconsNames.map((iconName, index) => {
                const icon = icons.find(
                  (icon) => icon.name.toUpperCase() === iconName
                );
                return (
                  <div className="mac-icon" key={index}>
                    {icon && (
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_BACKEND_URL_IMAGES +
                          icon.iconUrl
                        }
                        alt={icon.name}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mac-slider">
              <Slider {...settings}>
                {cachedProject.videos
                  .slice(1)
                  .filter((video) => !video.isHomePage)
                  .map((video, index) => (
                    <div className="ai-video-container" key={index}>
                      <video
                        preload="true"
                        muted={isSmallScreen}
                        src={
                          process.env.REACT_APP_BACKEND_URL_VIDEOS +
                          video.videoUrl +
                          "#t=0.001"
                        }
                        playsInline
                        controls={isSmallScreen}
                        autoPlay={false}
                        loop
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="mac-small-description">HOVER TO PLAY</div>
            <div className="mac-footer">
              <video
                preload="true"
                muted={isSmallScreen}
                src={
                  process.env.REACT_APP_BACKEND_URL_VIDEOS +
                  cachedProject.videos[0].videoUrl +
                  "#t=0.001"
                }
                playsInline
                controls={isSmallScreen}
                autoPlay={false}
                loop
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>

      <div className="mac-mobile-version">
        {cachedProject != null && icons != null ? (
          <React.Fragment>
            <div className="mac-mobile-videos">
              {cachedProject.videos
                .slice(1)
                .filter((video) => !video.isHomePage)
                .map((video, index) => (
                  <div className="ai-video-container" key={index}>
                    <video
                      preload="true"
                      muted={isSmallScreen}
                      src={
                        process.env.REACT_APP_BACKEND_URL_VIDEOS +
                        video.videoUrl +
                        "#t=0.001"
                      }
                      playsInline
                      controls={isSmallScreen}
                      autoPlay={false}
                      loop
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </div>
                ))}
            </div>
            <div className="mac-icons">
              {iconsNames.map((iconName, index) => {
                const icon = icons.find(
                  (icon) => icon.name.toUpperCase() === iconName
                );
                return (
                  <div className="mac-icon" key={index}>
                    {icon && (
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_BACKEND_URL_IMAGES +
                          icon.iconUrl
                        }
                        alt={icon.name}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default Mac;
