import React from "react";
import "./WorkAlongSide.css";

const WorkAlongSide = ({ brands }) => {
  return (
    <div className="workAlongSide-container" id="experience">
      <div className="workAlongSide-title">
        <h2 className="workAlong-title">WORKED ALONG SIDE</h2>
      </div>
      <div className="workAlongSide-brandName">
        <div className="brandName-container">
          {brands
            .reduce((chunks, item, index) => {
              const chunkIndex = Math.floor(index / 6);

              if (!chunks[chunkIndex]) {
                chunks[chunkIndex] = [];
              }

              chunks[chunkIndex].push(item);

              return chunks;
            }, [])
            .map((chunk, chunkIndex) => (
              <div key={chunkIndex}>{chunk.join(" // ")}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WorkAlongSide;
