import React, { useEffect, useState } from "react";
import "./ProjectDetails.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";
import Slider from "react-slick";

function ProjectDetails() {
  const url = window.location.pathname;
  const parts = url.split("/");
  const projectFromUrl = parts[parts.length - 1]
    .replace(/-/g, " ")
    .toUpperCase();
  const { project } = useFetchProjectByName(projectFromUrl);
  const [sliderImages, setSliderImages] = useState([]);
  const [fullScreenImages, setFullScreenImages] = useState([]);
  const [commonNumberDictionary, setCommonNumberDictionary] = useState({});
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);
    if (project) {
      // Filter slider images
      const sliderImages = project.images.filter((image) => image.isSlider);
      setSliderImages(sliderImages);

      // Filter full-screen images
      const fullScreenImages = project.images.filter(
        (image) => image.isFullScreen
      );
      setFullScreenImages(fullScreenImages);

      // Create dictionary for images and videos with the same common number
      const commonNumberDict = {};
      project.images.forEach((image) => {
        if (image.commonNumber && image.commonNumber !== 0) {
          if (!commonNumberDict[image.commonNumber]) {
            commonNumberDict[image.commonNumber] = [];
          }
          commonNumberDict[image.commonNumber].push(image);
        }
      });
      project.videos.forEach((video) => {
        if (video.commonNumber && video.commonNumber !== 0) {
          if (!commonNumberDict[video.commonNumber]) {
            commonNumberDict[video.commonNumber] = [];
          }
          commonNumberDict[video.commonNumber].push(video);
        }
      });
      setCommonNumberDictionary(commonNumberDict);
    }
  }, [project]);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };

  return (
    <div
      className="projectDetails-container"
      onClick={handleDocumentInteraction}
    >
      {project != null ? (
        <React.Fragment>
          <div className="projectDetails-slider">
            <Slider {...settings} className="productDetails-slider-desktop">
              {sliderImages.map(
                (image, index) =>
                  index % 4 === 0 && (
                    <div
                      key={index}
                      className="images-projectDetails-container"
                    >
                      {/* Large Image */}
                      <div className="projectDetails-bigimage-container">
                        <img
                          loading="lazy"
                          src={
                            process.env.REACT_APP_BACKEND_URL_IMAGES +
                            image.imageUrl
                          }
                          alt={image.alt}
                          className="projectDetails-large-image"
                        />
                      </div>
                      {/* Small Images */}
                      <div className="projectDetails-small-images">
                        {/* Render small images for the current large image */}
                        {project.images
                          .slice(index + 1, index + 3)
                          .map((smallImage, idx) => (
                            <div
                              className="projectDetails-smallimage-container"
                              key={idx}
                            >
                              <img
                                loading="lazy"
                                src={
                                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                                  smallImage.imageUrl
                                }
                                alt={smallImage.alt}
                                className="projectDetails-small-image"
                              />
                            </div>
                          ))}
                      </div>
                      {index + 3 < project.images.length && (
                        <div
                          className="projectDetails-bigimage-container"
                          key={index + 3}
                        >
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_BACKEND_URL_IMAGES +
                              project.images[index + 3].imageUrl
                            }
                            alt={project.images[index + 3].alt}
                            className="projectDetails-large-image"
                          />
                        </div>
                      )}
                    </div>
                  )
              )}
            </Slider>
            <div className="ProjectDetails-title-mobile">
              {project.description}
            </div>
            <div className="projectDetails-mobile">
              {sliderImages.map((image, index) => (
                <div className="mobile-image-projectDetails" key={index}>
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="ProjectDetails-title">{project.description}</div>
          <div className="projectsDetails-kit-container">
            {Object.keys(commonNumberDictionary).map((commonNumber, index) => (
              <div
                key={index}
                className={`projectDetails-row ${
                  index % 2 === 0 ? "even-row" : "odd-row"
                }`}
              >
                {commonNumberDictionary[commonNumber].map((item, idx) => (
                  <div key={idx} className="projectDetails-column">
                    {/* Conditionally render image or video component */}
                    {item.imageUrl && (
                      <img
                        loading="lazy"
                        src={
                          process.env.REACT_APP_BACKEND_URL_IMAGES +
                          item.imageUrl
                        }
                        alt={item.alt}
                      />
                    )}
                    {item.videoUrl && (
                      <video
                        preload="true"
                        muted={isSmallScreen}
                        src={
                          process.env.REACT_APP_BACKEND_URL_VIDEOS +
                          item.videoUrl +
                          "#t=0.001"
                        }
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        playsInline
                        controls={isSmallScreen}
                        autoPlay={false}
                        loop
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="projectDetails-Fullscreen">
            {fullScreenImages.map((image, index) => (
              <div className="projectDetails-imageContainer" key={index}>
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                  }
                  alt={image.alt}
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default ProjectDetails;
