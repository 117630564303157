import React, { useState, useEffect } from "react";
import "./Folie.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";

function Folie() {
  const projectName = "FOLIE KIDS";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false); // State to track user interaction
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");
  const transformDescription = (description) => {
    // Split the description into an array of categories
    const categories = description.split(" // ");
    // Rearrange the categories according to the desired format
    const firstPart = categories.slice(0, 3).join(" // ");
    const secondPart = categories.slice(3).join(" // ");
    // Return an array of JSX elements, each containing a <p> tag for the transformed description parts
    return [
      <p className="folie-small-desc">{firstPart}</p>,
      <p className="folie-small-desc">{secondPart}</p>,
    ];
  };
  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);
    // Check if project data is cached in sessionStorage
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);

  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };

  return (
    <div className="folie-container" onClick={handleDocumentInteraction}>
      <div className="folie-desktop-version">
        {cachedProject != null ? (
          <React.Fragment>
            <div
              className="folie-first-row"
              style={{
                backgroundImage: `url('${
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }')`,
              }}
            >
              <a
                className="folie-link"
                href="https://www.instagram.com/folie.kidz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CLICK TO VISIT THE PAGE
              </a>
            </div>

            <div className="folie-second-row">
              {transformDescription(cachedProject.description)}
              <div className="folie-videos">
                {cachedProject.videos.map((video, index) => (
                  <div className="folie-video-container" key={index}>
                    <video
                      preload="true"
                      src={
                        process.env.REACT_APP_BACKEND_URL_VIDEOS +
                        video.videoUrl +
                        "#t=0.001"
                      }
                      playsInline
                      muted={isSmallScreen}
                      controls={isSmallScreen}
                      autoPlay={false}
                      loop
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </div>
                ))}
              </div>
              <div className="folie-hover">HOVER TO PLAY</div>
            </div>
            <div className="folie-third-row">
              {cachedProject.images.slice(1).map((image, index) => (
                <div
                  key={index}
                  className="folie-image-row"
                  style={{
                    backgroundImage: `url('${
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }')`,
                  }}
                >
                  {index === 0 && (
                    <div className="folie-image-desc">{image.alt}</div>
                  )}
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
      <div className="folie-mobile-version">
        {cachedProject != null ? (
          <React.Fragment>
            <div className="folie-first-row-mobile">
              <img
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[0].imageUrl
                }
                alt={cachedProject.images[0].alt}
              />
            </div>
            <div className="folie-mobile-second-row">
              {transformDescription(cachedProject.description)}
              <div className="folie-videos">
                {cachedProject.videos.map((video, index) => (
                  <div className="folie-video-container" key={index}>
                    <video
                      preload="true"
                      muted={isSmallScreen}
                      playsInline
                      controls={isSmallScreen}
                      autoPlay={false}
                      loop
                      src={
                        process.env.REACT_APP_BACKEND_URL_VIDEOS +
                        video.videoUrl +
                        "#t=0.001"
                      }
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="folie-mobile-third-row">
              {cachedProject.images.slice(1).map((image, index) => (
                <div key={index} className="folie-mobile-image-row">
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default Folie;
