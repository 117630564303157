import React from "react";
import "./LetsConnect.css";

function LetsConnect() {
  const email = "nourjabre@gmail.com";
  return (
    <div className="letConnect-container" id="LetsConnect">
      <div className="letsConnect-mobile-title">CONTACT</div>
      <div className="letsConnect-title">
        <a href={`mailto:${email}`} className="title-link">
          LET’S CONNECT
        </a>
      </div>
      <div className="letConnect-footer">
        <div className="footer-logo">
          <img
            loading="lazy"
            src={process.env.PUBLIC_URL + "/Logo/nourjabr.png"}
            alt="website logo"
            width={250}
          />
        </div>
        <div className="footer-title">NOUR JABR</div>
      </div>
    </div>
  );
}

export default LetsConnect;
