import React, { useEffect, useState, useRef } from "react";
import "./settings.css";
import DashSideBar from "../../shared/DashSideBar";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { useAuth } from "../../Auth/AuthContext";
import { Password } from "primereact/password";
import axios from "axios";
import { Toast } from "primereact/toast";

function Settings() {
  const toast = useRef(null);
  const { user, updateUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [passwordloading, setPLoading] = useState(false);
  const {
    handleSubmit: handleSubmitUserInfo,
    control: controlUserInfo,
    setValue: setValueUserInfo,
    formState: { errors: errorsUserInfo },
  } = useForm({
    defaultValues: {
      username: user.userName || "", // Set default to an empty string if user.userName is undefined
      email: user.email || "", // Set default to an empty string if user.email is undefined
    },
  });

  // Form for change password
  const {
    watch,
    handleSubmit: handleSubmitChangePassword,
    control: controlChangePassword,
    formState: { errors: errorsChangePassword },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmnewPassword: "",
    },
  });

  useEffect(() => {
    // Set default values for user information form
    setValueUserInfo("username", user.userName || "");
    setValueUserInfo("email", user.email || "");

    // Set default values for change password form
  }, [user, setValueUserInfo]);

  const onSubmitUserInformation = async (data) => {
    setLoading(true);
    let Userdata = {
      userId: user.id,
      Name: data.username,
    };

    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "api/User/editName",
      Userdata
    );
    setLoading(false);
    if (response.data.success) {
      updateUser({ userName: data.username });

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Username Updated",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Username not updated",
        life: 3000,
      });
    }
  };

  const getFormErrorMessageUserInfo = (name) => {
    return errorsUserInfo[name] ? (
      <small className="p-error">{errorsUserInfo[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmitChangePassword = async (data) => {
    setPLoading(true);
    let userData = {
      userId: user.id,
      currentPassword: data.oldPassword,
      newPassword: data.newPassword,
    };

    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "api/User/changePassword",
      userData
    );
    setPLoading(false);

    if (response.data.success) {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Password Updated",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: response.data.message,
        life: 3000,
      });
    }
  };

  const getFormErrorMessageChangePassword = (name) => {
    return errorsChangePassword[name] ? (
      <small className="p-error">{errorsChangePassword[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="col-12 d-flex">
      <div className="col-3">
        <DashSideBar />
      </div>
      <div className="col-9">
        <div className="profile-title">Account Manager</div>

        <div className="info-section">
          <Toast ref={toast} />
          <div className="info-title">User Information</div>
          <form className="d-flex justify-content-between">
            <div className="p-field input-field">
              <Controller
                name="username"
                control={controlUserInfo}
                rules={{ required: "Username is required." }}
                render={({ field, fieldState }) => (
                  <>
                    <span className="p-float-label">
                      <InputText
                        id={field.name}
                        value={field.value || ""}
                        className={classNames({
                          "p-invalid": fieldState.error,
                        })}
                        onChange={(e) => field.onChange(e.target.value)}
                        style={{margin: "50px 0 20px 10px"}}
                      />
                      <label style={{fontSize:"15px", margin:"20px 0 20px 0px"}} htmlFor={field.name}>Username :</label>
                    </span>
                    {getFormErrorMessageUserInfo(field.name)}
                  </>
                )}
              />
            </div>
            <div className="p-field input-field">
              <Controller
                name="email"
                control={controlUserInfo}
                render={({ field, fieldState }) => (
                  <>
                    <span className="p-float-label">
                      <InputText
                        id={field.name}
                        disabled
                        value={field.value || ""}
                        style={{margin: "50px 30px 20px 0px"}}
                      />
                      <label style={{fontSize:"15px",margin:"20px 0 20px 0px"}} htmlFor={field.name}>Email :</label>
                    </span>
                  </>
                )}
              />
            </div>
          </form>
          <div className="submit-button">
            <Button
              type="submit"
              label="Save"
              loading={loading}
              onClick={handleSubmitUserInfo(onSubmitUserInformation)}
            />
          </div>
        </div>
        <div className="reset-password-section">
          <div className="reset-title">Change Password</div>
          <form>
            <div className="oldpass">
              <Controller
                name="oldPassword"
                control={controlChangePassword}
                rules={{ required: "Password is required." }}
                render={({ field, fieldState }) => (
                  <>
                    <span className="p-float-label">
                      <Password
                        id={field.name}
                        feedback={false}
                        toggleMask
                        value={field.value}
                        className={classNames({
                          "p-invalid": fieldState.error,
                        })}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                      <label htmlFor={field.name}>Old Password</label>
                    </span>
                    {getFormErrorMessageChangePassword(field.name)}
                  </>
                )}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="p-field input-field">
                <Controller
                  name="newPassword"
                  control={controlChangePassword}
                  rules={{
                    required: "New Password is required.",
                    minLength: {
                      value: 6,
                      message:
                        "New Password must be at least 6 characters long.",
                    },
                    pattern: {
                      value:
                        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                      message:
                        "New Password must contain at least one number and one special character.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <span className="p-float-label">
                        <Password
                          id={field.name}
                          toggleMask
                          feedback={false}
                          value={field.value}
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>New Password</label>
                      </span>
                      {getFormErrorMessageChangePassword(field.name)}
                    </>
                  )}
                />
              </div>
              <div className="p-field input-field">
                <Controller
                  name="confirmnewPassword"
                  control={controlChangePassword}
                  rules={{
                    required: "Confirm New Password is required.",
                    validate: (value) => {
                      const newPasswordValue = watch("newPassword", ""); // Assuming the field name is "newPassword"
                      return (
                        value === newPasswordValue || "Passwords do not match."
                      );
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <span className="p-float-label">
                        <Password
                          id={field.name}
                          toggleMask
                          feedback={false}
                          value={field.value}
                          className={classNames({
                            "p-invalid": fieldState.error,
                          })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Confirm New Password</label>
                      </span>
                      {getFormErrorMessageChangePassword(field.name)}
                    </>
                  )}
                />
              </div>
            </div>
          </form>
          <div className="submit-button">
            <Button
              type="submit"
              label="Save"
              loading={passwordloading}
              onClick={handleSubmitChangePassword(onSubmitChangePassword)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
