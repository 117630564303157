import React, { useState, useEffect } from "react";
import "./FeaturedWork.css";

function FeaturedWork({ videos }) {
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");
  const firstThreeVideos = videos.slice(0, 3);
  const lastTwoVideos = videos.slice(-2);
  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);
  }, []);
  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };

  return (
    <div
      className="feature-work-container"
      onClick={handleDocumentInteraction}
      id="featured"
    >
      <div className="featured-title">FEATURED WORK (click to play)</div>
      <div className="video-container">
        <div className="featured-first-row-video">
          {firstThreeVideos.map((video, index) => (
            <div key={index} className="featured-video-item">
              <video
                preload="true"
                muted={isSmallScreen}
                src={
                  process.env.REACT_APP_BACKEND_URL_VIDEOS +
                  video.videoUrl +
                  "#t=0.001"
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                playsInline
                controls={isSmallScreen}
                autoPlay={false}
                loop
              />
            </div>
          ))}
        </div>
        <div className="feature-second-row">
          {lastTwoVideos.map((video, index) => (
            <div key={index} className="featured-video-item-second-row">
              <video
                preload="true"
                muted={isSmallScreen}
                src={
                  process.env.REACT_APP_BACKEND_URL_VIDEOS +
                  video.videoUrl +
                  "#t=0.001"
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                playsInline
                controls={isSmallScreen}
                autoPlay={false}
                loop
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturedWork;
