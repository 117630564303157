import React, { useState, useEffect } from "react";
import "./Halu.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";

function Halu() {
  const projectName = "HALU COFFE SHOP & BAKERY";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false); // State to track user interaction
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mediaQuery = window.matchMedia("(max-width: 500px)");

  useEffect(() => {
    setIsSmallScreen(mediaQuery.matches);
    // Check if project data is cached in sessionStorage
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      console.log(1);
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, []);

  const handleMouseEnter = (event) => {
    if (isUserInteracted) {
      event.target.play();
    }
  };

  const handleMouseLeave = (event) => {
    event.target.pause();
  };
  const handleDocumentInteraction = () => {
    setIsUserInteracted(true); // Set user interaction flag to true
  };
  return (
    <div className="Halu-container">
      {cachedProject != null ? (
        <React.Fragment>
          <div className="haluFirstImage">
            <img
              src={
                process.env.REACT_APP_BACKEND_URL_IMAGES +
                cachedProject.images[0].imageUrl
              }
              alt={cachedProject.images[0].alt}
            />
            <div className="project-description">
              {cachedProject.description}
            </div>
          </div>

          <div className="secondrow-halu" onClick={handleDocumentInteraction}>
            <div className="first-col-image">
              <img
                loading="lazy"
                src={
                  process.env.REACT_APP_BACKEND_URL_IMAGES +
                  cachedProject.images[1].imageUrl
                }
                alt={cachedProject.images[1].alt}
              />
            </div>
            <div className="second-col-video">
              <video
                preload="true"
                muted={isSmallScreen}
                src={
                  process.env.REACT_APP_BACKEND_URL_VIDEOS +
                  cachedProject.videos[0].videoUrl +
                  "#t=0.001"
                }
                playsInline
                controls={isSmallScreen}
                autoPlay={false}
                loop
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
          <div className="dynamic-rows">
            {cachedProject.images.slice(2).map((image, index) => (
              <div className="dynamic-row" key={index}>
                <img
                  loading="lazy"
                  src={
                    process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                  }
                  alt={image.alt}
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Halu;
