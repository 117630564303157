import React, { useState, useEffect } from "react";
import "./Baladi.css";
import useFetchProjectByName from "../../../../hooks/useFetchProjectByName";
import Loading from "../../../components/Loading/Loading";
import Slider from "react-slick";

function Baladi() {
  const projectName = "BALADI BERBARA";
  const { project } = useFetchProjectByName(projectName);
  const [cachedProject, setCachedProject] = useState(null);

  useEffect(() => {
    const storedProject = sessionStorage.getItem(projectName);

    if (storedProject) {
      setCachedProject(JSON.parse(storedProject));
    } else if (project) {
      setCachedProject(project);
      sessionStorage.setItem(projectName, JSON.stringify(project));
    }
  }, [project, projectName]);
  const transformDescription = (description) => {
    // Split the description into an array of categories
    const categories = description.split(" // ");
    // Rearrange the categories according to the desired format
    const firstPart = categories.slice(0, 3).join(" // ");
    const secondPart = categories.slice(3).join(" // ");
    // Return an array of JSX elements, each containing a <p> tag for the transformed description parts
    return [
      <p className="baladi-small-desc">{firstPart}</p>,
      <p className="baladi-small-desc">{secondPart}</p>,
    ];
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div className="baladi-container">
      {cachedProject != null ? (
        <React.Fragment>
          <div className="desktop-berbara-version">
            <div className="baladi-slider">
              <Slider {...settings}>
                {cachedProject.images.map(
                  (image, index) =>
                    // Only render images where index % 3 is 0 (every third image)
                    index % 3 === 0 && (
                      <div key={index} className="images-baladi-container">
                        {/* Large Image */}
                        <div className="baladi-bigimage-container">
                          <img
                            loading="lazy"
                            src={
                              process.env.REACT_APP_BACKEND_URL_IMAGES +
                              image.imageUrl
                            }
                            alt={image.alt}
                            className="baladi-large-image"
                          />
                        </div>
                        {/* Small Images */}
                        <div className="baladi-small-images">
                          {/* Render small images for the current large image */}
                          {cachedProject.images
                            .slice(index + 1, index + 3)
                            .map((smallImage, idx) => (
                              <div
                                className="baladi-smallimage-container"
                                key={idx}
                              >
                                <img
                                  loading="lazy"
                                  src={
                                    process.env.REACT_APP_BACKEND_URL_IMAGES +
                                    smallImage.imageUrl
                                  }
                                  alt={smallImage.alt}
                                  className="baladi-small-image"
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                )}
              </Slider>
            </div>
            {transformDescription(cachedProject.description).map(
              (element, index) => (
                <React.Fragment key={index}>{element}</React.Fragment>
              )
            )}
          </div>

          <div className="berbara-mobile-version">
            <div className="berbara-title">{cachedProject.name}</div>
            <div className="berbara-desc">{cachedProject.description}</div>
            <div className="mobile-berbara-images">
              {cachedProject.images.map((image, index) => (
                <div className="berbara-mobile-item" key={index}>
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_BACKEND_URL_IMAGES + image.imageUrl
                    }
                    alt={image.alt}
                    className="baladi-small-image"
                  />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Baladi;
