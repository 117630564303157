import React from "react";
import "./BrandIcons.css";

function BrandIcons({ icons }) {
  return (
    <div className="brandicons-container">
      <div className="brand-icons">
        {icons
          .reduce((rows, icon, index) => {
            const rowIndex = Math.floor(index / 6);

            if (!rows[rowIndex]) {
              rows[rowIndex] = [];
            }

            rows[rowIndex].push(icon);

            return rows;
          }, [])
          .map((row, rowIndex) => (
            <div key={rowIndex} className="icon-row">
              {row.map((icon, iconIndex) => (
                <div key={iconIndex} className="icon-image">
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_BACKEND_URL_IMAGES + icon.icon}
                    alt={icon.name}
                  />
                </div>
              ))}
            </div>
          ))}
      </div>
      <div className="brand-icons-mobile">
        {icons.map((row, index) => (
          <div key={index} className="icon-image-mobile">
            <img
              loading="lazy"
              src={process.env.REACT_APP_BACKEND_URL_IMAGES + row.icon}
              alt={row.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BrandIcons;
